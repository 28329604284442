<template>
  <div>
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.title"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>
    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card class="mt-9">
          <v-data-table dense
                        :headers="headers"
                        :items="active_data_items"
                        :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Member`s Data</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearch"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.id }}
                </td>
                <td>
                  {{ item.date_registered }}
                </td>
                <td>
                  {{ item.company_name }}
                </td>
                <td>
                  {{ item.address }}
                </td>
                <td>
                  {{ item.type_of_business }}
                </td>
                <td>
                  {{ item.contact_no }}
                </td>
                <td>
                  {{ formatPrice(item.annual_gross_income) }}
                </td>
                <td class="text-center">
                  <v-icon
                    v-show="item.is_active"
                    v-if="!is_deleting"
                    class="mr-2"
                    color="error"
                    @click="change_status(item,0)"
                  >
                    {{icons.mdiToggleSwitchOff}}
                  </v-icon>
                  <v-icon
                    v-show="!item.is_active"
                    v-if="!is_deleting"
                    class="mr-2"
                    color="success"
                    @click="change_status(item,1)"
                  >
                    {{icons.mdiToggleSwitch}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item>

        <v-card class="mt-9">
          <v-data-table dense
                        :headers="headers"
                        :items="deactivated_data_items"
                        :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Member Data</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearch"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.id }}
                </td>
                <td>
                  {{ item.date_registered }}
                </td>
                <td>
                  {{ item.company_name }}
                </td>
                <td>
                  {{ item.address }}
                </td>
                <td>
                  {{ item.type_of_business }}
                </td>
                <td>
                  {{ item.contact_no }}
                </td>
                <td>
                  {{ formatPrice(item.annual_gross_income) }}
                </td>
                <td class="text-center">
                  <v-icon
                    v-show="item.is_active"
                    v-if="!is_deleting"
                    class="mr-2"
                    color="error"
                    @click="change_status(item,0)"
                  >
                    {{icons.mdiToggleSwitchOff}}
                  </v-icon>
                  <v-icon
                    v-show="!item.is_active"
                    v-if="!is_deleting"
                    class="mr-2"
                    color="success"
                    @click="change_status(item,1)"
                  >
                    {{icons.mdiToggleSwitch}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiCheck, mdiClose
    , mdiAccountOff
    , mdiAccountCheck
    , mdiPlus
    , mdiToggleSwitchOff
    , mdiToggleSwitch
    , mdiEyeCircle, mdiAccountOutline, mdiGoogleCirclesCommunities
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      active_data_items: [],
      deactivated_data_items: [],
      search: '',
      is_deleting: false,
      tab: '',
      tabs: [
        {title: 'ACTIVE', icon: mdiAccountOutline},
        {title: 'IN ACTIVE', icon: mdiGoogleCirclesCommunities},
      ],
      headers: [
        {text: 'ID', value: 'id', sortable: true},
        {text: 'Date Registered', value: 'date_registered', sortable: true},
        {text: 'Name', value: 'company_name', sortable: true},
        {text: 'Address', value: 'address', sortable: true},
        {text: 'Type', value: 'type_of_business', sortable: true},
        {text: 'Contact #', value: 'contact_no', sortable: true},
        {text: 'Annual Gross', value: 'annual_gross_income', sortable: true},
        {text: 'Action', value: 'annual_gross_income', sortable: false},
      ],
    }
  }
  export default {
    props: {
      is_active: Number,
      type_of_business: String,
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiAccountOff
          , mdiToggleSwitch
          , mdiToggleSwitchOff
          , mdiAccountCheck
          , mdiPlus
          , mdiEyeCircle
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('members_information', ['list_of_member', 'change_status_member']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      initialize_data() {
        const data = new FormData()
        if (this.type_of_business != undefined) {
          data.append('is_individual', this.type_of_business === 'Individual' ? 1 : 0);
          data.append('is_business', this.type_of_business === 'Business' ? 1 : 0);
        }
        this.list_of_member(data)
          .then(response => {
            this.active_data_items = response.data[0].active
            this.deactivated_data_items = response.data[0].in_active
            this.is_deleting = false
          })
          .catch(error => {
            console.log(error)
          })
      },
      async change_status(item, status) {
        const data = new FormData()
        data.append('id', item.id);
        data.append('status', status);
        this.is_deleting = true
        await this.change_status_member(data)
          .then(response => {
            this.change_snackbar({
              show: true,
              color: response.status === 200 ? 'success' : 'error',
              text: response.data,
            })
            this.initialize_data()
          })
          .catch(error => {
            console.log(error)
          })
      },
    },
  }
</script>
